import React, { useState } from 'react'
import styled from 'styled-components'
import { MdEdit } from 'react-icons/md'
import { Input } from 'reactstrap'
import numeral from 'numeral'
import StatsCard from './StatsCard'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


import { Bar as ChartBar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  height: calc(100% - 40px);
`

const ChartRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const BarBackground = styled.div`
  background-color: #fff;
  height: 35px;
  width: 95%;
  border-radius: 0 15px 15px 0;
`

const BarFill = styled.div`
  position: relative;
  top: 0;
  left: 0;
  background-color: #198754;
  width: ${({ width }) => width};
  height: 100%;
  border-radius: 0 15px 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BarLabel = styled.p`
  color: #fff;
  margin: 0 4px;
  padding: 0;
  font-size: 16px;
  text-align: center;
`

const RangeRow = styled.div`
  display: flex;
  margin: 0 20px 0 4px;
  justify-content: space-between;
`

const RangeLabel = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #fff;
`

const TargetEditRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
`

export const SalesTarget = ({ monthlyContracts, salesTarget, updateSalesTarget, employeeRole }) => {
  const [inputShowing, setInputShowing] = useState(false)

  const monthlyTotal = monthlyContracts?.reduce((acc, contract) => {
    return acc + contract.revenueData?.yearlyTotal
  }, 0)

  const percentCompleted = monthlyTotal >= salesTarget?.target
    ? '100%'
    : `${Math.round((monthlyTotal / salesTarget?.target) * 100) || 0}%`

  const handleTargetChange = (e) => {
    const newTarget = parseInt(e.target.value)
    const targetReached = monthlyTotal >= newTarget
    updateSalesTarget({
      met: targetReached,
      target: newTarget
    })
    setInputShowing(false)
  }

  const showInputToggle = employeeRole === 'administrator' || employeeRole === 'owner'

  const maxChart = salesTarget.target
  const minChart = 0

  const optionsChart = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#000000'
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.x);
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          display: true,
          color: '#FFFFFF',
          callback: (value, index, values) => {
            let label = index === 0 || index === values.length - 1 ? value : "";

            const currencyFormat = {
              notation: "compact",
              compactDisplay: "short",
              style: 'currency',
              currency: 'USD'
            }

            if (label !== "" && label != 0) {
              return new Intl.NumberFormat('en-US', currencyFormat).format(label);
            } else {
              return label
            }
          },
        },
        grid: {
          display: false,
          drawTicks: false,
          drawBorder: false,
          drawOnChartArea: false
        },
        min: minChart,
        max: maxChart
      },

      y: {
        ticks: {
          display: false,
          color: '#000000',
        },
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false,
          drawOnChartArea: false
        },
      }
    },
  };

  const dataChart = {
    labels: ['Sales'],
    datasets: [{
      axis: 'y',
      data: [monthlyTotal],
      // fill: false,
      backgroundColor: (contextChart) => {

        const value = contextChart.dataset.data[contextChart.dataIndex];
        const colors = {
          RED: '#dc3545',
          YELLOW: '#ffc107',
          GREEN: '#198754'
        }

        const totalColor = Object.entries(colors).length

        const minColorValue = Math.round(maxChart / totalColor)
        const midColorValue = Math.round(maxChart / 2)

        if (value < midColorValue) {
          return colors.GREEN
        } if (value < maxChart) {
          return colors.GREEN
        } else {
          return colors.GREEN
        }
      },
      barThickness: 25
    }],
  }

  return (
    <StatsCard title={'Monthly Sales Target'}>
      <StatsContainer>
        <TargetEditRow>
          {inputShowing ? (
            <Input
              style={{
                width: '50%',
                height: 24,
                marginRight: 20
              }}
              type="number"
              name="salesTarget"
              id="salesTarget"
              placeholder="Enter Sales Target"
              defaultValue={salesTarget?.target}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleTargetChange(e)
                }
              }}
            />
          ) : null}
          {showInputToggle ? (
            <MdEdit size={24} color="white" style={{ cursor: 'pointer' }} onClick={() => setInputShowing(!inputShowing)} />
          ) : null}
        </TargetEditRow>
        {/* <div>
          <BarBackground>
            <BarFill width={percentCompleted} >
              <BarLabel>${numeral(monthlyTotal).format('0,0')}</BarLabel>
            </BarFill>
          </BarBackground>
          <RangeRow>
            <RangeLabel>0</RangeLabel>
            <RangeLabel>${numeral(salesTarget?.target).format('0,0')}</RangeLabel>
          </RangeRow>
        </div> */}
        <div style={{ height: 50, width: '70%' }}>
          <ChartBar options={optionsChart} data={dataChart} />
        </div>
      </StatsContainer>
    </StatsCard>
  )
}
