import React, { Component } from 'react'
import styled from 'styled-components'
import StatsCard from './StatsCard'
// import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


import { Bar as ChartBar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StatsContainer = styled.div`
  display: flex;
  /* height : 100%; */
  height: calc(100% - 40px);
  /* flex-direction: column; */
  /* flex: 1;
  justify-content: center; */
  /* align-items : center; */
`


const ChartRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
`

const Bar = styled.div`
  background-color: #198754;
  height: 15px;
  ${({ width }) => (width ? 'width: ' + width * 70 + '%;' : '')}
  min-width: fit-content;
`

const BarLabel = styled.p`
  color: #fff;
  margin: 0 4px;
  padding: 0;
  font-size: 10px;
  text-align: center;
`

const ChartLabel = styled.span`
  color: #fff;
  margin-left: 4px;
  font-size: 12px;
  font-weight: 300;
`

const Metric = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  margin-bottom: 0;
  font-size: 40px;
`

export const ConversionRate = ({ stats }) => {
  const percentCompleted = `${Math.round((stats?.won / stats?.created) * 100) ||
    0}%`

  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#000000'
        }
      },
      title: {
        display: true,
        text: percentCompleted,
        font: {
          size: 25,
        },
        color: '#000000'
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        font: {
          size: 15
        }
      },

      y: {
        ticks: {
          // display: false
          color: '#000000'
        }
      }
    }
  };

  const labels = ['Proposal Created', 'Proposal Won']
  const dataProposals = [stats.created, stats.won]

  const data = {
    labels,
    datasets: [
      {
        axis: 'y',
        data: dataProposals,
        fill: false,
        backgroundColor: ['#0088FE', '#198754', '#dc3545'],
        barThickness: 25,
        borderWidth: 1,
      },
      // {
      //   label: 'Created',
      //   data: [stats.created],
      //   backgroundColor: '#0088FE',
      //   // barThickness: 25,
      //   // borderWidth: 1
      // },
      // {
      //   label: 'Won',
      //   data: [stats.won],
      //   backgroundColor: '#198754',
      //   // barThickness: 25,
      //   // borderWidth: 1
      // },
    ],
  };

  return (
    <StatsCard title={'Conversion Rate'}>
      <StatsContainer>
        {/* <Metric>{percentCompleted}</Metric> */}
        {/* <ChartRow>
          <Bar width={stats?.won / stats?.created}>
            <BarLabel>{stats?.won}</BarLabel>
          </Bar>
          <ChartLabel>Proposals won</ChartLabel>
        </ChartRow>
        <ChartRow>
          <Bar width={stats?.created ? 1 : null}>
            <BarLabel>{stats?.created}</BarLabel>
          </Bar>
          <ChartLabel>Proposals created</ChartLabel>
        </ChartRow>
        <h1 style={{ color: 'white' }}>Hola</h1> */}


        <div style={{ width: '100%' }}>
          <ChartBar options={options} data={data} />
        </div>

      </StatsContainer>
    </StatsCard>
  )
}
