import React, { Fragment } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ProposalIcon from 'components/icons/ProposalIcon'
import WalkthroughIcon from 'components/icons/WalkthroughIcon'
import EstimatorIcon from 'components/icons/EstimatorIcon'
//import JobsIcon from 'components/icons/JobsIcon'
import InspectionIcon from 'components/icons/InspectionIcon'
import MapIcon from 'components/icons/MapIcon'
import ClientIcon from 'components/icons/ClientIcon'
import EmployeeIcon from 'components/icons/EmployeeIcon'
import SettingsIcon from 'components/icons/SettingsIcon'
import SalesDashboardIcon from 'components/icons/SalesDashboardIcon'
import Icon from 'components/icons'
import { BsPuzzleFill } from 'react-icons/bs'
// import { FaStore } from 'react-icons/fa'
// import THDProLogo from 'assets/img/thd-pro-logo.png'
// import CSLogo from 'assets/img/culture-studio-logo.png'
import RoutePlusLogo from 'assets/img/Route_plus_logo_W.png'
import {
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import * as actions from 'api'
import Avatar from 'components/lib/Avatar'
import { persistor } from 'store'

import { encryptJsonWithAES } from 'utils/encrypter'
import * as acl from 'utils/acl.service'
import MenuContext from '../../contexts/Menu'

const AreaStyle = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 200px;
  max-width: 200px;
  background-color: ${props => props.theme.colors.light0};
  color: white;
  padding-top: 70px;
  span {
    margin-left: 20px;
  }
`

const MyLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 16px;
  color: black;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
  }

  &.active {
    background-color: ${props => props.theme.colors.clarity};
    color: black;
  }
`

const SubLink = styled(NavLink)`
  display: block;
  padding-left: 40px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 16px;
  color: ${props => props.theme.colors.light2};
  opacity: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
  }

  &.active {
    background: linear-gradient(
      0.25turn,
      ${props => props.theme.colors.dark0},
      ${props => props.theme.colors.dark1}
    );
  }
`

// const SubShopLink = styled(NavLink)`
//   display: block;
//   padding-left: 40px;
//   padding-top: 6px;
//   padding-bottom: 6px;
//   font-size: 16px;
//   color: ${props => props.theme.colors.light2};
//   opacity: 0;

//   &:hover {
//     background-color: rgba(0, 0, 0, 0.3);
//     color: white;
//   }

//   &.active {
//     background: linear-gradient(
//       0.25turn,
//       ${props => props.theme.colors.dark0},
//       ${props => props.theme.colors.dark1}
//     );
//   }
// `
/*
const Heading = styled.div`
  display: block;
  padding-left: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 40px;
  margin-bottom: 20px;
`
*/

const NavView = styled(Nav)`
  margin-left: 5px;
  margin-top: 5px;
`

const ProfileNavView = styled(Nav)`
  margin-left: 1px;
  margin-top: 5px;
  margin-bottom: 10px !important;
`

const DropDownView = styled(UncontrolledDropdown)`
  padding: 0px;
  margin-top: -10px;
  margin-bottom: -10px;
`

const DropDownToggleView = styled(DropdownToggle)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.light0};

  &:hover {
    color: ${props => props.theme.colors.light0};
  }
`
const AvatarWrapper = styled.div`
  display: inline-block;
  height: 32px;
  width: 32px;
  border-radius: 21px;
  margin-right: 10px;
  font-size: 8px;
  margin-top: -2px;
`
const ProfileView = styled.div`
  display: inline-block;
`

const ProfileWrapper = styled.div`
  margin: 0px;
  padding: 0px;
  margin-left: 2px;
  padding-top: 5px;
  font-weight: 400;
  font-size: 16px;
`

// const SubLinkCaptionSpan = styled.div`
//   display: flex;
// `

// const SubLinkCaptionText = styled.p`
//   font-size: 0.75rem;
//   margin-bottom: 8px;
// `

class LeftNav extends React.Component {
  async componentDidMount() {
    await this.props.getPlanControlInfo('inspections.create')
  }

  useCompany = async company => {
    if (
      company &&
      company.company &&
      company.company._id !== this.props.company._id
    ) {
      await this.props.refreshToken(company.company._id)
      window.location.reload()
    }
  }

  renderTeams = employment => {
    if (!employment) {
      return null
    }

    const renderedEmployment = employment.map(e => {
      return (
        e &&
        e.company &&
        e.company._id && (
          <DropdownItem onClick={() => this.useCompany(e)} key={e.company._id}>
            {e.company.name}
          </DropdownItem>
        )
      )
    })

    return <Fragment>{renderedEmployment}</Fragment>
  }

  logout = async () => {
    this.props.logout()
    this.props.history.push('/login/')
    await persistor.flush()
    await persistor.purge()
  }

  render() {
    const { isLoggedIn, profile, employment, company } = this.props

    const encryptedBillingData = encryptJsonWithAES({
      stripeCustomerId: company?.billing?.stripe?.customerId
    })

    const billingLink = `${process.env.REACT_APP_LOGIN_DOMAIN}/billing?billingData=${encryptedBillingData}`

    // const betaMode = company && company.betaMode === true
    return (
      <MenuContext.Consumer>
        {menuContext => (
          <AreaStyle
            className={`left-nav ${menuContext.isOpen ? 'is-open' : ''}`}
          >
            {isLoggedIn && (
              <>
                <NavView className="d-md-none ml-left" navbar>
                  {employment && company && (
                    <UncontrolledDropdown nav inNavbar>
                      <DropDownToggleView style={{ color: 'white' }} nav caret>
                        {company.name}
                      </DropDownToggleView>
                      <DropdownMenu>
                        {this.renderTeams(employment)}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </NavView>
                <ProfileNavView className="d-md-none ml-left" navbar>
                  <DropDownView nav inNavbar>
                    <DropDownToggleView nav caret>
                      <AvatarWrapper>
                        <Avatar
                          size={32}
                          url={
                            profile && profile.avatar ? profile.avatar.url : ''
                          }
                          name={profile && profile.name.full}
                        />
                      </AvatarWrapper>
                      <ProfileView>
                        <ProfileWrapper>
                          {profile && profile.name
                            ? profile.name.first
                            : 'User'}
                        </ProfileWrapper>
                      </ProfileView>
                    </DropDownToggleView>
                    <DropdownMenu right>
                      {/* <DropdownItem href="/changePassword">
                        Change Password
                      </DropdownItem> */}
                      <DropdownItem href="/profile/">
                        Profile & Settings
                      </DropdownItem>
                      <DropdownItem className="logout" onClick={this.logout}>
                        Sign Out
                      </DropdownItem>
                    </DropdownMenu>
                  </DropDownView>
                </ProfileNavView>
              </>
            )}

            {acl.isAllowed('dashboard.show') && (
              <MyLink
                onClick={menuContext.closeLeftMenu}
                to="/dashboard/sales/"
              >
                <SalesDashboardIcon color="#000" style={{ marginRight: 16 }} />
                Dashboard
              </MyLink>
            )}

            <MyLink
              onClick={menuContext.closeLeftMenu}
              to="/dashboard/walkthroughs/"
            >
              <WalkthroughIcon color="#000" style={{ marginRight: 16 }} />
              Walkthroughs
            </MyLink>

            {acl.isAllowed('estimates.list') && (
              <MyLink
                onClick={menuContext.closeLeftMenu}
                to="/dashboard/estimates/"
              >
                <EstimatorIcon color="#000" style={{ marginRight: 16 }} />
                Estimates
              </MyLink>
            )}

            {acl.isAllowed('proposals.list') && (
              <MyLink
                onClick={menuContext.closeLeftMenu}
                to="/dashboard/proposals/"
              >
                <ProposalIcon color="#000" style={{ marginRight: 16 }} />
                Proposals
              </MyLink>
            )}

            {/*
              <MyLink
                onClick={menuContext.closeLeftMenu}
                to="/dashboard/services/"
              >
                <JobsIcon color="#FFF" style={{ marginRight: 16 }} />
                Jobs
              </MyLink>

              */}

            {this.props.allowInspections.isAllowed && (
              <>
                <MyLink
                  onClick={menuContext.closeLeftMenu}
                  to="/dashboard/inspections/"
                >
                  <InspectionIcon color="#000" style={{ marginRight: 16 }} />
                  Inspections
                </MyLink>
                <hr />
              </>
            )}

            {acl.isAllowed('map.view') && (
              <MyLink onClick={menuContext.closeLeftMenu} to="/dashboard/map/">
                <MapIcon color="#000" style={{ marginRight: 16 }} />
                Map
              </MyLink>
            )}

            {acl.isAllowed('locations.list') && (
              <MyLink
                onClick={menuContext.closeLeftMenu}
                to="/dashboard/locations/"
              >
                <Icon
                  icon="MdLocationOn"
                  color="#000"
                  style={{ marginLeft: -6, marginRight: 16 }}
                />
                Locations
              </MyLink>
            )}

            {acl.isAllowed('clients.list') && (
              <MyLink
                onClick={menuContext.closeLeftMenu}
                to="/dashboard/clients/"
              >
                <ClientIcon color="#000" style={{ marginRight: 16 }} />
                Clients
              </MyLink>
            )}

            {acl.isAllowed('employees.list') && (
              <MyLink
                onClick={menuContext.closeLeftMenu}
                to="/dashboard/employees/"
              >
                <EmployeeIcon color="#000" style={{ marginRight: 16 }} />
                Employees
              </MyLink>
            )}
            <hr />

            {/* <MyLink
              onClick={menuContext.closeLeftMenu}
              to="/dashboard/shop/"
              id="shop"
            >
              <FaStore color="#FFF" size={20} style={{ marginRight: 16 }} />
              Shop
            </MyLink>
            <div
              className="subshopmenu"
              style={{ paddingTop: 2, paddingBottom: 2 }}
            >
              <SubShopLink
                onClick={menuContext.closeLeftMenu}
                to="/dashboard/shop/culture-studio"
                className='subshoplink'
              >
                Merchandise
                <SubLinkCaptionSpan>
                  <SubLinkCaptionText>Powered By </SubLinkCaptionText>
                  <img 
                    style={{
                      height: 25,
                      marginLeft: 2
                    }} 
                    src={CSLogo} 
                    alt='culture-studio-logo'
                  />
                </SubLinkCaptionSpan>
              </SubShopLink>
              <SubShopLink
                onClick={menuContext.closeLeftMenu}
                to="/dashboard/shop/hdpro"
                className='subshoplink'
              >
                Supplies
                <SubLinkCaptionSpan>
                  <SubLinkCaptionText>Powered By </SubLinkCaptionText>
                  <img 
                    style={{
                      height: 20,
                      marginLeft: 2
                    }} 
                    src={THDProLogo} 
                    alt='home-depot-pro-logo'
                  />
                </SubLinkCaptionSpan>
              </SubShopLink>
            </div>
            <hr /> */}
            {acl.isAllowed('merchant.read') && (
              <>
                {/* <MyLink
                  onClick={menuContext.closeLeftMenu}
                  to="/dashboard/integrations/"
                  id="integrations"
                >
                  <BsPuzzleFill color="#FFF" size={20} style={{ marginRight: 16 }} />
                  Integrations
                </MyLink> */}
                <MyLink
                  onClick={menuContext.closeLeftMenu}
                  to="/dashboard/library/"
                  id="settings"
                >
                  <SettingsIcon color="#000" style={{ marginRight: 16 }} />
                  Settings
                </MyLink>
                <div className='settings-container' >
                  {acl.isAllowed('merchant.update') && (
                    <SubLink
                      className="sub"
                      to="/dashboard/library/company"
                      onClick={menuContext.closeLeftMenu}
                    >
                      Company
                    </SubLink>
                  )}
                  <SubLink
                    onClick={menuContext.closeLeftMenu}
                    className="sub"
                    to="/dashboard/library/services"
                  >
                    Services
                  </SubLink>

                  {/* <SubLink
                    onClick={menuContext.closeLeftMenu}
                    className="sub"
                    to="/dashboard/library/swept"
                  >
                    Swept
                  </SubLink> */}

                  {acl.isAllowed('billing.create') && (
                    <SubLink
                      onClick={menuContext.closeLeftMenu}
                      className="sub"
                      to={{ pathname: billingLink }}
                      target="_blank"
                    >
                      Billing
                    </SubLink>
                  )}
                </div>
              </>
            )}
            {/* <hr />
            <MyLink
              to={{pathname: 'https://routeplus.getroute.com'}}
              id="routeplus"
              target="_blank"
            >
              <img 
                src={RoutePlusLogo} 
                color="#FFF" 
                style={{ marginRight: 10, width: 25 }} 
                alt="R+"
              />
              Route+
            </MyLink> */}
          </AreaStyle>
        )}
      </MenuContext.Consumer>
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.user.token ? true : false,
  profile: state.user.profile,
  employment: state.user.employment,
  company: state.user.currentCompany,
  allowInspections: state.pcl.inspections.create
})

const enhancedLeftNav = connect(
  mapStateToProps,
  actions
)(LeftNav)
export default withRouter(enhancedLeftNav)
